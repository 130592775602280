import axios from 'axios';
import { defineStore } from 'pinia';
import { HTTP, FILE_HTTP } from '../services/http';
import { useAppStore } from './app';

export const useApi = defineStore({
  id: 'api',
  state: () => ({
    silent: false, // if not silent, a loading overlay will pop up for api calls
    count: 0,
    throw: false, // if no throw, the default app notification will popup on non-200 status codes
    unwrap: true,
    errors: [],
  }),
  actions: {
    _handleError(e, errorMessage = '') {
      if (this.throw === true) throw e;
      if (typeof this.throw === 'number' && this.throw === e.response?.status) throw e;
      const data = e.response?.data;
      if (data?.reasons) {
        data.reasons.forEach((reason) => {
          this.error(`${reason.field} ${reason.reason}`);
        });
      } else {
        this.error(data?.message || e.message || errorMessage);
      }
      return false;
    },
    async request(fn, errorMessage = '') {
      try {
        this.count += 1;
        const result = await fn();
        const returnValue = this.unwrap ? result.data : result;
        // Default true to catch empty string responses
        return returnValue || true;
      } catch (e) {
        return this._handleError(e, errorMessage);
      } finally {
        this.count -= 1;
        if (this.count === 0) {
          this.$patch({
            throw: false,
            silent: false,
          });
        }
      }
    },
    error(message) {
      if (message) {
        const app = useAppStore();
        app.addNotification({
          type: 'error',
          message,
          timeout: 5000,
        });
      }
      return this;
    },
    throwOnError(status) {
      this.throw = status || true;
      return this;
    },
    silently() {
      this.silent = true;
      return this;
    },
    async getProjectFull(projectId) {
      const [project, notes, documents, lodgements] = await Promise.all([
        this.getProjectOverview(projectId),
        this.getProjectNotes(projectId),
        this.getProjectDocuments(projectId),
        this.getLodgements(projectId),
      ]);
      return {
        ...project.model,
        notes: notes.model,
        documents: documents.model,
        lodgements: lodgements.model,
      };
    },
    getProjects() {
      return this.request(() => HTTP.get('/projects'), 'Failed to fetch projects.');
    },
    searchProjects(payload) {
      return this.request(
        () => HTTP.post('/retrofitProjectSearch', payload),
        'Failed to fetch projects.'
      );
    },
    searchProjectsAdvanced(payload) {
      return this.request(
        () => HTTP.post('/RetrofitProjectSearch/advance', payload),
        'Failed to fetch projects.'
      );
    },
    createProject(payload, acceptUprnCheck) {
      return this.request(
        () => HTTP.post(`/RetrofitProject?acceptUprnCheck=${acceptUprnCheck}`, payload),
        'Failed to create the project.'
      );
    },
    getProject(id) {
      return this.request(
        () => HTTP.get(`/RetrofitProject/${id}`),
        `Failed to fetch project (${id}).`
      );
    },
    updateProject(project) {
      return this.request(() => HTTP.put(`/RetrofitProject`, project), `Failed to update project.`);
    },
    updateProjectAddress(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/address`, project),
        `Failed to update project address.`
      );
    },
    updateProjectTenure(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/tenure`, project),
        `Failed to update project tenure.`
      );
    },
    updateProjectInformation(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/propertyinformation`, project),
        `Failed to update project property information.`
      );
    },
    updateProjectQuestions(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/questions`, project),
        `Failed to update project questions.`
      );
    },
    updateProjectLocalAuthority(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/localAuthority`, project),
        `Failed to update project local authority.`
      );
    },
    updateProjectUprn(project) {
      return this.request(
        () => HTTP.put(`/RetrofitProject/uprn`, project),
        `Failed to update project uprn.`
      );
    },
    deleteProject(id) {
      return this.request(() => HTTP.delete(`/RetrofitProject/${id}`));
    },
    updateProjectOutcome(projectId, intendedOutcome) {
      return this.request(() => HTTP.put(`/IntendedOutcome/${projectId}`, intendedOutcome));
    },
    getProjectNotes(projectId) {
      return this.request(() => HTTP.get(`/RetrofitProjectNote/${projectId}/GetAll`));
    },
    addProjectNote(projectId, note) {
      return this.request(() => HTTP.post(`/RetrofitProjectNote/${projectId}`, note));
    },
    updateProjectNote(projectId, noteId, payload) {
      return this.request(() => HTTP.put(`/RetrofitProjectNote/${projectId}/${noteId}`, payload));
    },
    deleteProjectNote(projectId, noteId) {
      return this.request(() => HTTP.delete(`/RetrofitProjectNote/${projectId}/${noteId}`));
    },
    getProjectDocument(projectId, documentId) {
      return this.request(() => HTTP.get(`/SupportingDocument/${projectId}/${documentId}`));
    },
    getProjectDocuments(projectId) {
      return this.request(() => HTTP.get(`/SupportingDocument/${projectId}/GetAll`));
    },
    addProjectDocument(projectId, document) {
      return this.request(() => HTTP.post(`/SupportingDocument/${projectId}`, document));
    },
    getProjectDocumentDownloadUrl(projectId, documentId, lodgementId) {
      return this.request(() =>
        FILE_HTTP.get(`/File/Download/${projectId}/${documentId}/${lodgementId}`)
      );
    },
    updateProjectDocument(projectId, documentId, payload) {
      return this.request(() =>
        HTTP.put(`/SupportingDocument/${projectId}/${documentId}`, payload)
      );
    },
    deleteProjectDocument(projectId, documentId, lodgementId) {
      return this.request(() =>
        HTTP.delete(
          `/SupportingDocument/${projectId}/${documentId}${lodgementId ? '/' + lodgementId : ''}`
        )
      );
    },
    addEvaluatorResponse(projectId, payload) {
      return this.request(() => HTTP.post(`/EvaluatorResponse/${projectId}`, payload));
    },
    updateEvaluatorResponse(projectId, evaluatorResponseId, payload) {
      return this.request(() =>
        HTTP.put(`/EvaluatorResponse/${projectId}/${evaluatorResponseId}`, payload)
      );
    },
    deleteEvaluatorResponse(projectId, evaluatorResponseId) {
      return this.request(() =>
        HTTP.delete(`/EvaluatorResponse/${projectId}/${evaluatorResponseId}`)
      );
    },
    getProjectOverview(id) {
      return this.request(
        () => HTTP.get(`/RetrofitProject/${id}/overview`),
        `Failed to fetch project (${id}).`
      );
    },
    getProjectByReference(ref) {
      return this.request(
        () => HTTP.get(`/retrofitProject/projectReference/${ref}`),
        `Failed to fetch project by reference (${ref}).`
      );
    },
    startProject(payload, params = {}, acceptUprnCheck = false) {
      return this.request(
        () =>
          HTTP.post(
            `/retrofitProjectStart/getReference?acceptUprnCheck=${acceptUprnCheck}`,
            payload,
            { params }
          ),
        'Failed to get project reference.'
      );
    },
    completeProject(id, payload, params = {}) {
      const formData = new FormData();
      if (payload.file) {
        formData.append('file', payload.file, { type: 'text/xml' });
      }
      return this.request(
        () =>
          HTTP.post(
            `/retrofitProjectComplete/${id}?acceptSamePrePostScores=${
              payload.confirmSapScore || false
            }&acceptUprnCheck=${payload.confirmUprnCheck || false}&acceptAddressCheck=${
              payload.confirmAddress1 || false
            }`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              params,
            }
          ),
        'Failed to complete project.'
      );
    },
    regenerateCertificate(projectReference) {
      return this.request(
        () => HTTP.post(`/retrofitProject/RegenerateCompletionCertificate/${projectReference}`),
        'Failed to regenerate certificate.'
      );
    },
    amendProject(id, payload, params = {}) {
      const formData = new FormData();
      if (payload.file) {
        formData.append('file', payload.file, { type: 'text/xml' });
      }
      return this.request(
        () =>
          HTTP.post(
            `/retrofitProjectComplete/${id}/amend?acceptSamePrePostScores=${
              payload.confirmSapScore || false
            }&acceptUprnCheck=${payload.confirmUprnCheck || false}&acceptAddressCheck=${
              payload.confirmAddress1 || false
            }`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              params,
            }
          ),
        'Failed to amend project.'
      );
    },
    uncompleteProject(id) {
      return this.request(
        () => HTTP.post(`/retrofitProjectComplete/${id}/uncomplete`),
        `Failed to uncomplete project (${id}).`
      );
    },
    voidProject(id) {
      return this.request(
        () => HTTP.post(`/retrofitProjectComplete/${id}/void`),
        `Failed to void project (${id}).`
      );
    },
    blockEmail(id, payload) {
      return this.request(
        () => HTTP.put(`/retrofitProject/${id}/blockEmail`, payload),
        `Failed to block email for project (${id}).`
      );
    },
    unvoidProject(id) {
      return this.request(
        () => HTTP.post(`/retrofitProjectComplete/${id}/unvoid`),
        `Failed to unvoid project (${id}).`
      );
    },
    refundProject(id, shouldRefund, shouldRefundLodgements) {
      return this.request(
        () =>
          HTTP.post(
            `/retrofitProjectComplete/${id}/refund?shouldRefund=${shouldRefund}&shouldRefundLodgements=${shouldRefundLodgements}`
          ),
        `Failed to refund project (${id}).`
      );
    },
    refundLodgement(projectId, id, shouldRefund) {
      return this.request(
        () =>
          HTTP.post(`/lodgementComplete/${projectId}/${id}/refund?shouldRefund=${shouldRefund}`),
        `Failed to refund lodgement (${id}).`
      );
    },
    getLodgements(projectId) {
      return this.request(
        () => HTTP.get(`/lodgement/${projectId}/GetAll`),
        `Failed to fetch lodgements (${projectId}).`
      );
    },
    searchLodgements(payload) {
      return this.request(
        () => HTTP.post('/LodgementSearch', payload),
        'Failed to fetch lodgements.'
      );
    },
    searchLodgementsAdvanced(payload) {
      return this.request(
        () => HTTP.post('/LodgementSearch/advance', payload),
        'Failed to fetch lodgements.'
      );
    },
    createLodgement(projectId, payload) {
      return this.request(
        () => HTTP.post(`/lodgement/${projectId}`, payload),
        `Failed to create the lodgement (${projectId}).`
      );
    },
    completeLodgement(projectId, lodgementId, params = {}) {
      return this.request(
        () => HTTP.post(`/lodgementComplete/${projectId}/${lodgementId}`, null, { params }),
        `Failed to complete the lodgement (${projectId} ${lodgementId}).`
      );
    },
    uncompleteLodgement(projectId, lodgementId) {
      return this.request(
        () => HTTP.post(`/lodgementComplete/${projectId}/${lodgementId}/uncomplete`),
        `Failed to uncomplete the lodgement (${projectId} ${lodgementId}).`
      );
    },
    resendLodgementCertificate(projectId, lodgementId) {
      return this.request(
        () => HTTP.post(`/lodgementComplete/${projectId}/${lodgementId}/ResendCertificate`),
        `Failed to uncomplete the lodgement (${projectId} ${lodgementId}).`
      );
    },
    getLodgement(projectId, lodgementId) {
      return this.request(
        () => HTTP.get(`/lodgement/${projectId}/${lodgementId}`),
        `Failed to fetch lodgement (${lodgementId} ${projectId}).`
      );
    },
    updateLodgement(projectId, lodgementId, payload) {
      return this.request(
        () => HTTP.put(`/lodgement/${projectId}/${lodgementId}`, payload),
        `Failed to update the lodgement (${projectId} ${lodgementId}).`
      );
    },
    deleteLodgement(projectId, lodgementId) {
      return this.request(() => HTTP.delete(`/Lodgement/${projectId}/${lodgementId}`));
    },
    amendLodgementMeasures(projectId, lodgementId, payload) {
      return this.request(() =>
        HTTP.put(`/Lodgement/${projectId}/${lodgementId}/amendmeasures`, payload)
      );
    },
    getAppData() {
      return this.request(() => HTTP.get('/appData'), 'Failed to fetch app data.');
    },
    getMediumTermPlan(projectId) {
      return this.request(
        () => HTTP.get(`/mediumTermImprovementPlan/${projectId}`),
        `Failed to fetch medium term improvement plan (${projectId}).`
      );
    },
    updateMediumTermPlan(mediumTermImprovementPlanId, payload) {
      return this.request(
        () => HTTP.put(`/MediumTermImprovementPlan/${mediumTermImprovementPlanId}`, payload),
        `Failed to update medium term improvement plan (${mediumTermImprovementPlanId}).`
      );
    },
    initialiseAssessment(rdsap) {
      const formData = new FormData();
      formData.append('file', rdsap, { type: 'text/xml' });
      return this.request(() =>
        HTTP.post('/RetrofitAssessmentInitialise', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      );
    },
    uploadPreInstallationFile(projectId, lodgementId, rdsap) {
      const formData = new FormData();
      formData.append('file', rdsap, { type: 'text/xml' });
      return this.request(() =>
        HTTP.post(`/StandaloneLodgement/${projectId}/${lodgementId}/preinstallation`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      );
    },
    uploadPostInstallationFile(projectId, lodgementId, rdsap) {
      const formData = new FormData();
      formData.append('file', rdsap, { type: 'text/xml' });
      return this.request(() =>
        HTTP.post(`/StandaloneLodgement/${projectId}/${lodgementId}/postinstallation`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      );
    },
    createAssessment(payload, forceAddress) {
      return this.request(
        () => HTTP.post(`/RetrofitAssessment?forceAddress=${forceAddress}`, payload),
        'Failed to create assessment.'
      );
    },
    getAssessment(id) {
      return this.request(
        () => HTTP.get(`/RetrofitAssessment/${id}`),
        `Failed to fetch assessment with ID: “${id}”.`
      );
    },
    updateAssessment(payload) {
      return this.request(() => HTTP.put(`/RetrofitAssessment`, payload));
    },
    deleteAssessment(id) {
      return this.request(() => HTTP.delete(`/RetrofitAssessment/${id}`));
    },
    getAssessmentById(retrofitAssessmentId) {
      return this.request(
        () => HTTP.get(`/RetrofitAssessment/${retrofitAssessmentId}`),
        `Failed to fetch assessment with id: “${retrofitAssessmentId}”.`
      );
    },
    getAssessmentByReference(reference) {
      return this.request(
        () => HTTP.get(`/RetrofitAssessment/assessmentReference/${reference}`),
        `Failed to fetch assessment with reference: “${reference}”.`
      );
    },
    assessmentLookup(assessmentReference, postcode) {
      return this.request(() =>
        HTTP.post('/RetrofitAssessmentLookup', {
          assessmentReference,
          postcode,
        })
      );
    },
    searchAssessments(payload) {
      return this.request(
        () => HTTP.post('/RetrofitAssessmentSearch', payload),
        'Failed to fetch assessments.'
      );
    },
    searchAssessmentsAdvanced(payload) {
      return this.request(
        () => HTTP.post('/RetrofitAssessmentSearch/advance', payload),
        'Failed to fetch assessments.'
      );
    },
    completeAssessment(assessmentId, payload, params = {}) {
      return this.request(
        () => HTTP.post(`/RetrofitAssessmentComplete/${assessmentId}`, payload, { params }),
        'Failed to complete assessment.'
      );
    },
    downloadAssessmentFile(assessmentId) {
      return this.request(
        () => HTTP.get(`/RetrofitAssessment/Download/${assessmentId}`),
        `Failed to download assessment with id: “${assessmentId}”.`
      );
    },
    getImprovementOptions(projectId) {
      return this.request(() =>
        HTTP.get(`/ImprovementOptionEvaluationContoller/${projectId}/GetAll`)
      );
    },
    createImprovementOption(projectId, payload = {}) {
      return this.request(
        () => HTTP.post(`/ImprovementOptionEvaluationContoller/${projectId}`, payload),
        'Failed to create improvement option'
      );
    },
    updateImprovementOption(optionId, payload) {
      return this.request(() =>
        HTTP.put(`ImprovementOptionEvaluationContoller/${optionId}`, payload)
      );
    },
    deleteImprovementOption(projectId, optionId) {
      return this.request(() =>
        HTTP.delete(`ImprovementOptionEvaluationContoller/${projectId}/${optionId}`)
      );
    },
    revertImprovementOptions(projectId) {
      return this.request(() =>
        HTTP.post(`/ImprovementOptionEvaluationContoller/${projectId}/revert`)
      );
    },
    selectImprovementOptionForPlan(projectId, improvementOptionEvaluationId) {
      return this.request(
        () => HTTP.post(`/MediumTermImprovementPlan/${projectId}/${improvementOptionEvaluationId}`),
        `Failed to create plan from improvement option: “${projectId}” "${improvementOptionEvaluationId}".`
      );
    },
    updateRiskAssessment(projectId, payload) {
      return this.request(
        () => HTTP.put(`/RiskAssessment/${projectId}`, payload),
        'Failed to update risk assessment.'
      );
    },
    createRetrofitDesign(projectId, improvementOptionEvaluationId, payload) {
      return this.request(
        () => HTTP.post(`/RetrofitDesign/${projectId}/${improvementOptionEvaluationId}`, payload),
        'Failed to create retrofit design.'
      );
    },
    updateRetrofitDesign(retrofitDesignId, payload) {
      return this.request(() => HTTP.put(`/RetrofitDesign/${retrofitDesignId}`, payload));
    },
    deleteRetrofitDesign(projectId, retrofitDesignId) {
      return this.request(() => HTTP.delete(`/RetrofitDesign/${projectId}/${retrofitDesignId}`));
    },
    createPaymentIntent(payload) {
      return this.request(() => HTTP.post(`/StripePayment`, payload));
    },
    updatePaymentIntent(id, payload) {
      return this.request(() => HTTP.put(`/StripePayment/${id}`, payload));
    },
    tradeCheck(payload) {
      return this.request(() => HTTP.post(`/TradeCheck`, payload));
    },
    getAccount() {
      return this.request(() => HTTP.get(`/Account`));
    },
    updateAccount(payload) {
      return this.request(() => HTTP.put(`/Account`, payload), 'Failed to update account.');
    },
    getUser() {
      return this.request(() => HTTP.get(`/Account/mydetails`));
    },
    acceptTerms() {
      return this.request(() => HTTP.post(`/Account/acceptTerms`));
    },
    updateUser(payload) {
      return this.request(
        () => HTTP.put(`/Account/mydetails`, payload),
        'Failed to update user details.'
      );
    },
    accountAddUser(payload) {
      return this.request(() => HTTP.put('/Account/user', payload));
    },
    accountRemoveUser(accountId) {
      return this.request(() => HTTP.delete('/Account/user', { data: { accountId } }));
    },
    generateApiKey(keyname) {
      return this.request(() => HTTP.post(`/RetrofitAccountApiKey/${keyname}`));
    },
    getApiKeys() {
      return this.request(() => HTTP.get('/RetrofitAccountApiKey'));
    },
    deleteApiKey(tmapikey) {
      return this.request(() => HTTP.delete(`/RetrofitAccountApiKey/${tmapikey}`));
    },
    requestLodgementBasicFilterExport(payload) {
      return this.request(() =>
        HTTP.post(
          `/Export/Lodgements/BasicFilter?bypassDateRange=${payload.bypassDateRange || false}`,
          payload
        )
      );
    },
    requestLodgementAdvancedFilterExport(payload) {
      return this.request(() =>
        HTTP.post(
          `/Export/Lodgements/Advancedfilter?bypassDateRange=${payload.bypassDateRange || false}`,
          payload
        )
      );
    },
    requestProjectBasicFilterExport(payload) {
      return this.request(() =>
        HTTP.post(
          `/Export/Projects/BasicFilter?bypassDateRange=${payload.bypassDateRange || false}`,
          payload
        )
      );
    },
    requestProjectAdvancedFilterExport(payload) {
      return this.request(() =>
        HTTP.post(
          `/Export/Projects/Advancedfilter?bypassDateRange=${payload.bypassDateRange || false}`,
          payload
        )
      );
    },
    listExports() {
      return this.request(() => HTTP.get('/Export/GetAll'));
    },
    downloadExport(exportId) {
      return this.request(() => HTTP.get(`/Export/Download/csv/${exportId}`));
    },
    removeExport(exportId) {
      return this.request(() => HTTP.delete(`/Export/${exportId}`));
    },
    removePendingExport(exportId) {
      return this.request(() => HTTP.delete(`/Export/Pending/${exportId}`));
    },
    lookupAddress(postcode) {
      return this.request(() => HTTP.get(`/AddressLookup/${postcode}`));
    },
    lookupUPRN(uprn) {
      return this.request(() => HTTP.get(`/AddressLookup/uprn/${uprn}`));
    },
    lookupMCS(mcsCertificateReference, postcode) {
      return this.request(() => HTTP.get(`/MCSLookup/${mcsCertificateReference}/${postcode}`));
    },
    getOfgemCheckLog(projectId) {
      return this.request(() => HTTP.get(`/OfgemCheckLog/${projectId}`));
    },
    getLastOfgemCheckLogCalls() {
      return this.request(() => HTTP.get(`/OfgemCheckLog/latest`));
    },
    getLastOfgemCheckLogCallsForValue(value) {
      return this.request(() => HTTP.get(`/OfgemCheckLog/latest/${value}`));
    },
    getUPRNList(value, paginationToken) {
      return this.request(() => HTTP.post(`/UPRNIndex/${value}`, { paginationToken }));
    },
    getOfgemSupplierUMRChecks(projectId) {
      return this.request(() => HTTP.get(`/OfgemSupplierCheck/${projectId}`));
    },
    getEnergySupplierNotificationsByProjectId(projectId) {
      return this.request(() => HTTP.get(`/EnergySupplierNotifications/project/${projectId}`));
    },
    getSignedUrl(projectId, filename) {
      return this.request(() =>
        FILE_HTTP.get(
          `/File/GetPresignedPost/${projectId}?filename=${encodeURIComponent(filename)}`
        )
      );
    },
    getAuditSignedUrl(projectId, filename, timestamp, latitude, longitude, platform, address) {
      return this.request(() =>
        FILE_HTTP.get(
          `/AuditFile/GetPresignedPost/${projectId}?filename=${encodeURIComponent(
            filename
          )}&timestamp=${timestamp}&latitude=${latitude}&longitude=${longitude}&platform=${platform}&address=${encodeURIComponent(
            address
          )}`
        )
      );
    },
    getPresignedUrl(payload) {
      return this.request(() => FILE_HTTP.post(`/File/GetPresignedUrl`, payload));
    },
    getPresignedUrlForRemediationNote(payload) {
      return this.request(() => FILE_HTTP.post(`/File/GetPresignedUrl/RemediationNote`, payload));
    },
    getPresignedUrlTemp(payload) {
      return this.request(() => FILE_HTTP.post(`/File/GetPresignedUrl/Temp`, payload));
    },
    getPresignedAuditUrl(payload) {
      return this.request(() => FILE_HTTP.post(`/AuditFile/GetPresignedUrl`, payload));
    },
    deleteAuditFile(link) {
      return this.request(() => FILE_HTTP.post(`/AuditFile/Delete`, { link }));
    },
    getPresignedCertificateUrl(link) {
      return this.request(() => FILE_HTTP.get(`/CertificateFile/download/${link}`));
    },
    getPresignedRdSAPUrl(payload) {
      return this.request(() => FILE_HTTP.post(`/File/GetPresignedUrl/RdSap`, payload));
    },
    uploadFileToS3(url, file, config) {
      return this.request(() => axios.put(url, file, config));
    },
    getInvoices(accountId) {
      return this.request(() => HTTP.get(`/TaxInvoice/${accountId}/GetAll`));
    },
    getFinanceInvoices(tmln) {
      return this.request(() => HTTP.get(`/TaxInvoice/Finance/${tmln}/GetAll`));
    },
    getFinanceInvoiceNumber(invoiceNumber) {
      return this.request(() => HTTP.get(`/TaxInvoice/Finance/${invoiceNumber}`));
    },
    downloadInvoice(retrofitAccountId, period, invoiceNumber) {
      return this.request(() =>
        FILE_HTTP.get(`File/DownloadInvoice/${retrofitAccountId}/${period}/${invoiceNumber}`)
      );
    },
    getTransactions(accountId) {
      return this.request(() => HTTP.get(`/Transaction/${accountId}/GetAll`));
    },
    searchFinanceBasicTransactions(payload) {
      return this.request(() => HTTP.post(`/Transaction/Search`, payload));
    },
    getFinanceTransactions(tmln) {
      return this.request(() => HTTP.get(`/Transaction/Finance/${tmln}/GetAll`));
    },
    searchFinanceTransactions(payload) {
      return this.request(() => HTTP.post(`/Transaction/SearchAdvance`, payload));
    },
    getPASResponse(projectId, responseId) {
      return this.request(() => HTTP.get(`/PAS/${projectId}/${responseId}`));
    },
    updatePASResponse(projectId, responseId, payload) {
      return this.request(() => HTTP.put(`/PAS/${projectId}/${responseId}`, payload));
    },
    searchPASResponses(payload) {
      return this.request(() => HTTP.post(`/PASResponseSearch`, payload));
    },
    getAudits(projectId) {
      return this.request(() => HTTP.get(`/Audit/${projectId}/GetAll`));
    },
    createAudit(projectId, payload) {
      return this.request(() => HTTP.post(`/Audit/${projectId}`, payload));
    },
    updateAudit(projectId, payload) {
      return this.request(() => HTTP.put(`/Audit/${projectId}`, payload));
    },
    deleteAudit(projectId, auditId, params) {
      return this.request(() => HTTP.delete(`/Audit/${projectId}/${auditId}`, { params }));
    },
    resolveAudit(projectId, payload) {
      return this.request(() => HTTP.post(`/Audit/${projectId}/resolve`, payload));
    },
    getLodgementLegacyBranches() {
      return this.request(() => HTTP.get(`/LegacyLodgement/branches`));
    },
    getLodgementLegacyBranchLink(branchId, refreshToken) {
      return this.request(() =>
        HTTP.post(`/LegacyLodgement/transfer/${branchId}`, { refreshToken })
      );
    },
    getCertificateSendHistory(projectId) {
      return this.request(() => HTTP.get(`/CertificateSendHistory/${projectId}`));
    },
    // @TODO Remove, Deprecated
    getQuestionSets() {
      return this.request(() => HTTP.get('/QuestionSetReview/list'));
    },
    getQuestionSetReview(questionSetId) {
      return this.request(() => HTTP.get(`/QuestionSetReview/${questionSetId}`));
    },
    approveQuestionSetReview(questionSetId) {
      return this.request(() => HTTP.put(`/QuestionSetReview/${questionSetId}/approve`));
    },
    getOnsiteAudit(projectId) {
      return this.request(() => HTTP.get(`/OnsiteAudit/${projectId}`));
    },
    getOnsiteAuditors() {
      return this.request(() => HTTP.get(`/OnsiteAudit/auditors`));
    },
    updateOnsiteAuditBooking(projectId, payload) {
      return this.request(() => HTTP.put(`/OnsiteAudit/${projectId}/booking`, payload));
    },
    reviewCompleteOnsiteAudit(projectId, payload) {
      return this.request(() => HTTP.post(`/OnsiteAudit/${projectId}/complete`, payload));
    },
    bypassCompleteOnsiteAudit(projectId, payload) {
      return this.request(() => HTTP.post(`/OnsiteAudit/${projectId}/bypass`, payload));
    },
    bypassCompleteDesktopAudit(projectId, payload) {
      return this.request(() => HTTP.post(`/DesktopAudit/${projectId}/bypass`, payload));
    },
    moveToReview(projectId, payload) {
      return this.request(() => HTTP.post(`/OnsiteAudit/${projectId}/movetoreview`, payload));
    },
    adminMoveToReview(projectId) {
      return this.request(() => HTTP.post(`/OnsiteAudit/${projectId}/adminmovetoreview`));
    },
    clearAudit(projectId) {
      return this.request(() => HTTP.post(`/OnsiteAudit/${projectId}/clearAudit`));
    },
    clearDesktopAudit(projectId) {
      return this.request(() => HTTP.post(`/DesktopAudit/${projectId}/clearAudit`));
    },
    getManualQuestionSets(projectId) {
      return this.request(() => HTTP.get(`/OnsiteAudit/${projectId}/Forms`));
    },
    addFormToOnsiteAudit(projectId, auditQuestionId, measureId) {
      return this.request(() =>
        HTTP.put(`/OnsiteAudit/${projectId}/Form/${auditQuestionId}/${measureId}`)
      );
    },
    getBespokeAudit(bespokeAuditId, projectId) {
      return this.request(() => HTTP.get(`/BespokeAudit/${bespokeAuditId}/${projectId}`));
    },
    getBespokeAuditsByProjectId(projectId) {
      return this.request(() => HTTP.get(`/BespokeAudit/${projectId}`));
    },
    getBespokeAuditors() {
      return this.request(() => HTTP.get(`/BespokeAudit/auditors`));
    },
    updateBespokeAuditBooking(bespokeAuditId, projectId, payload) {
      return this.request(() => HTTP.put(`/BespokeAudit/${bespokeAuditId}/${projectId}/booking`, payload));
    },
    reviewCompleteBespokeAudit(bespokeAuditId, projectId, payload) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${bespokeAuditId}/${projectId}/complete`, payload)
      );
    },
    bypassCompleteBespokeAudit(bespokeAuditId, projectId, payload) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${bespokeAuditId}/${projectId}/bypass`, payload)
      );
    },
    createBespokeAudit(projectId, payload) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${projectId}/audit`, payload)
      );
    },
    moveBespokeAuditToReview(bespokeAuditId, projectId, payload) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${bespokeAuditId}/${projectId}/movetoreview`, payload)
      );
    },
    adminMoveBespokeAuditToReview(bespokeAuditId, projectId) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${bespokeAuditId}/${projectId}/adminmovetoreview`)
      );
    },
    clearBespokeAudit(bespokeAuditId, projectId) {
      return this.request(() =>
        HTTP.post(`/BespokeAudit/${bespokeAuditId}/${projectId}/clearAudit`)
      );
    },
    getBespokeAuditManualQuestionSets(bespokeAuditId, projectId) {
      return this.request(() => HTTP.get(`/BespokeAudit/${bespokeAuditId}/${projectId}/Forms`));
    },
    addFormToBespokeAudit(bespokeAuditId, projectId, auditQuestionId, measureId) {
      return this.request(() =>
        HTTP.put(
          `/BespokeAudit/${bespokeAuditId}/${projectId}/Form/${auditQuestionId}/${measureId}`
        )
      );
    },
    restartBespokeAuditForm(bespokeAuditId, projectId, auditFormId) {
      return this.request(() =>
        HTTP.put(`/BespokeAudit/${bespokeAuditId}/${projectId}/RevistFormRestart/${auditFormId}`, {})
      );
    },
    searchOnsiteAudits(payload) {
      return this.request(() => HTTP.post('/OnsiteAuditSearch/advance', payload));
    },
    searchDesktopAudits(payload) {
      return this.request(() => HTTP.post('/DesktopAuditSearch/advance', payload));
    },
    getOnsiteAuditVisitLogsByDate(date) {
      return this.request(() => HTTP.get(`/OnsiteAuditVisitLog/GetAll/${date}`));
    },
    getOnsiteAuditVisitLogsByProjectId(projectId) {
      return this.request(() => HTTP.get(`/OnsiteAuditVisitLog/GetProject/${projectId}`));
    },
    onsiteAuditVisitLogCheckIn(payload) {
      return this.request(() => HTTP.post(`/OnsiteAuditVisitLog/checkin`, payload));
    },
    onsiteAuditVisitLogCheckOut(payload) {
      return this.request(() => HTTP.post(`/OnsiteAuditVisitLog/checkout`, payload));
    },
    getBespokeAuditVisitLogsByProjectId(bespokeAuditId, projectId) {
      return this.request(() => HTTP.get(`/BespokeAuditVisitLog/GetProject/${bespokeAuditId}/${projectId}`));
    },
    bespokeAuditVisitLogCheckIn(payload) {
      return this.request(() => HTTP.post(`/BespokeAuditVisitLog/checkin`, payload));
    },
    bespokeAuditVisitLogCheckOut(payload) {
      return this.request(() => HTTP.post(`/BespokeAuditVisitLog/checkout`, payload));
    },
    getOnsiteAuditVisitLogByAuditor(payload) {
      return this.request(() => HTTP.post(`/OnsiteAuditVisitLog/GetAuditor`, payload));
    },

    getDesktopAudit(retrofitProjectId) {
      return this.request(() => HTTP.get(`/DesktopAudit/${retrofitProjectId}`));
    },
    assignDesktopAuditor(retrofitProjectId, payload) {
      return this.request(() => HTTP.put(`/DesktopAudit/${retrofitProjectId}/auditor`, payload));
    },
    addFormToDesktopAudit(retrofitProjectId, auditQuestionSetId, measureId) {
      return this.request(() =>
        HTTP.put(`/DesktopAudit/${retrofitProjectId}/form/${auditQuestionSetId}/${measureId}`)
      );
    },
    getDesktopAuditors() {
      return this.request(() => HTTP.get(`/DesktopAudit/auditors`));
    },
    desktopAuditMoveToReview(retrofitProjectId, payload) {
      return this.request(() =>
        HTTP.post(`/DesktopAudit/${retrofitProjectId}/MoveToReview`, payload)
      );
    },
    desktopAuditAdminMoveToReview(retrofitProjectId, payload) {
      return this.request(() =>
        HTTP.post(`/DesktopAudit/${retrofitProjectId}/AdminMoveToReview`, payload)
      );
    },
    desktopAuditComplete(retrofitProjectId, payload) {
      return this.request(() => HTTP.post(`/DesktopAudit/${retrofitProjectId}/Complete`, payload));
    },
    getDesktopAuditForms(retrofitProjectId) {
      return this.request(() => HTTP.get(`/DesktopAudit/${retrofitProjectId}/forms`));
    },

    getPresignedSurveyReportUrl(key) {
      return this.request(() => FILE_HTTP.get(`/SurveyReportFile/download/${key}`));
    },
    async getStandaloneLodgementProject(projectId) {
      const [project, documents, lodgements] = await Promise.all([
        this.getProject(projectId),
        this.getProjectDocuments(projectId),
        this.getLodgements(projectId),
      ]);
      return {
        ...project.model,
        documents: documents.model,
        lodgements: lodgements.model,
      };
    },
    getStandaloneLodgement(projectId, lodgementId) {
      return this.request(() => HTTP.get(`StandaloneLodgement/${projectId}/${lodgementId}`));
    },
    createStandaloneLodgement(payload) {
      return this.request(() => HTTP.post('/StandaloneLodgement', payload));
    },
    updateStandaloneLodgement(payload) {
      return this.request(() => HTTP.put('/StandaloneLodgement', payload));
    },
    exportBasicTransactions(payload) {
      return this.request(() => HTTP.post('/Export/transactions/basicfilter', payload));
    },
    exportTransactions(payload) {
      return this.request(() => HTTP.post('/Export/transactions/advancedfilter', payload));
    },
    switchAccount(accountId) {
      return this.request(() => HTTP.put(`/Account/switch/${accountId}`));
    },
    getAuditForm(projectId, auditFormId) {
      return this.request(() => HTTP.get(`/AuditForm/${projectId}/${auditFormId}`));
    },
    updateAuditForm(auditFormId, form) {
      return this.request(() => HTTP.put(`/AuditForm/${auditFormId}`, form));
    },
    searchAppointments(payload) {
      return this.request(() => HTTP.post('/AppointmentSearch', payload));
    },
    searchAppointmentsAdvanced(payload) {
      return this.request(() => HTTP.post('/AppointmentSearch/advance', payload));
    },
    searchOnsiteAppointments(payload) {
      return this.request(() => HTTP.post('/OnsiteAuditAppointmentSearch', payload));
    },
    searchOnsiteAppointmentsAdvanced(payload) {
      return this.request(() => HTTP.post('/OnsiteAuditAppointmentSearch/advance', payload));
    },
    getProjectRemediation(projectId) {
      return this.request(() => HTTP.get(`/Remediation/${projectId}`));
    },
    addRemediationEvidence(remediationId, payload) {
      return this.request(() => HTTP.post(`/RemediationEvidence/${remediationId}`, payload));
    },
    updateRemediationEvidence(remediationId, evidenceId, payload) {
      return this.request(() =>
        HTTP.put(`/RemediationEvidence/${remediationId}/${evidenceId}`, payload)
      );
    },
    removeRemediationEvidence(projectId, remediationId, evidenceId) {
      return this.request(() =>
        HTTP.delete(`/RemediationEvidence/${projectId}/${remediationId}/${evidenceId}`)
      );
    },
    reviewRemediationEvidence(projectId, remediationId, evidenceId, payload) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/${evidenceId}/review`, payload)
      );
    },
    resolveRemediationReason(
      projectId,
      remediationId,
      remediationNonComplianceReasonId,
      withNoAccess
    ) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/reasonresolve`, {
          remediationNonComplianceReasonId,
          withNoAccess,
        })
      );
    },
    escalateRemediation(projectId, remediationId) {
      return this.request(() =>
        HTTP.post(`/RemediationAdmin/${projectId}/${remediationId}/escalatenow`)
      );
    },
    extendRemediationDeadline(projectId, remediationId, numberOfDays) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/extenddeadline`, null, {
          params: { numberOfDays },
        })
      );
    },
    addInternalNote(projectId, remediationId, note) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/internalnote`, note)
      );
    },
    addRemediationResponse(projectId, remediationId, payload) {
      return this.request(() =>
        HTTP.post(`/Remediation/${projectId}/${remediationId}/respond`, payload)
      );
    },
    markRemediationConversationsAsRead(projectId, remediationId) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/markasread`, {})
      );
    },
    resolveRemediation(projectId, remediationId) {
      return this.request(() =>
        HTTP.put(`/RemediationAdmin/${projectId}/${remediationId}/resolved`)
      );
    },
    appointmentArrange(appointments) {
      const formData = new FormData();
      formData.append('file', appointments, appointments.name);
      return this.request(() =>
        HTTP.post('/AuditorAssign/appointments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      );
    },
    searchRemediation(payload) {
      return this.request(() => HTTP.post('/RemediationSearch', payload));
    },
    dashboardSearchRemediation(payload) {
      return this.request(() => HTTP.post('/RemediationSearch/Dashboard', payload));
    },
    searchRemediationAdvance(payload) {
      return this.request(() => HTTP.post('/RemediationSearch/Advance', payload));
    },
    searchRemediationEvidenceAdvance(payload) {
      return this.request(() => HTTP.post('/RemediationSearch/Evidence', payload));
    },
    searchRemediationConversationAdvance(payload) {
      return this.request(() => HTTP.post('/RemediationSearch/Conversation', payload));
    },
    latestRequiresReview(max = 50, backwardSearch = false) {
      return this.request(() =>
        HTTP.get(
          `/RemediationEvidence/LatestRequiresReview?max=${max}&backwardSearch=${backwardSearch}`
        )
      );
    },
    restartOnsiteAuditForm(projectId, auditFormId) {
      return this.request(() =>
        HTTP.put(`/OnsiteAudit/${projectId}/RevistFormRestart/${auditFormId}`, {})
      );
    },
    deleteCertificate(certificateSentDt, projectId) {
      return this.request(() =>
        HTTP.post(`/CertificateSendHistory/${projectId}`, { sentDt: certificateSentDt })
      );
    },
    getFunderReferencePostcode(postcode, funderReference) {
      return this.request(() => HTTP.get(`/FunderReference/postcode/${postcode}/${funderReference}`));
    },
    getAllFunders() {
      return this.request(() => HTTP.get(`/Funder/all`));
    },
    getSavedSearchById(id) {
      return this.request(() => HTTP.get(`/SavedSearch/${id}`));
    },
    getSavedSearchesByAccountAndPlatformAndModel(platform, model) {
      return this.request(() => HTTP.post(`/SavedSearch/GetAll`, { platform, model }));
    },
    createSavedSearch(payload) {
      return this.request(() => HTTP.post(`/SavedSearch`, payload));
    },
    updateSavedSearch(payload) {
      return this.request(() => HTTP.put(`/SavedSearch`, payload));
    },
    deleteSavedSearch(id) {
      return this.request(() => HTTP.delete(`/SavedSearch/${id}`));
    },
    getProjectDisputeUrl(id) {
      return this.request(() => HTTP.get(`/Dispute/project/${id}`));
    },
    requestTotp() {
        return this.request(() => HTTP.post(`/Account/requestTotp`))
    },
    onCompleteTotp(form) {
        return this.request(() => HTTP.post(`/Account/completeTotp`, form))
    },
    requestSms(form) {
        return this.request(() => HTTP.post(`/Account/requestSms`, form))
    },
    onCompleteSms(form) {
        return this.request(() => HTTP.post(`/Account/completeSms`, form))
    },
  },
});
